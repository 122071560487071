import Head from "next/head";
import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import { AnimatePresence, motion } from "framer-motion";

import { MenuProvider } from "@/context";
import { initSentry } from "@/util";
import { Navigation, Overlay, Footer } from "@/components";

import "../styles/globals.css";
import "../styles/syntax.css";

initSentry();

const App: React.FC<AppProps> = ({
  Component,
  pageProps,
  router,
  // @ts-ignore err is not publicly exposed
  err,
}) => (
  <>
    <Head>
      <title>Luke Brown</title>
      <meta
        property="og:title"
        content="Freelance Website Developer & Designer | Luke Brown"
      />
      <meta
        property="og:description"
        content="Cardiff based website developer, serving clients and agencies of all shapes and sizes to provide online experiences that are a joy to use."
      />
      <meta property="og:image" content="/images/og.png" />
    </Head>
    <MenuProvider>
      <Overlay />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial="initial"
          animate="enter"
          exit="exit"
          key={router.route}
          transition={{ duration: 0.2, type: "linear" }}
          variants={{
            initial: { opacity: 0 },
            enter: { opacity: 1 },
            exit: { opacity: 0 },
          }}
        >
          <div className="flex flex-col justify-between md:min-h-screen">
            <div>
              <Navigation />
              <Component {...pageProps} err={err} />
            </div>
            <Footer />
          </div>
        </motion.div>
      </AnimatePresence>
    </MenuProvider>
  </>
);

export default App;
